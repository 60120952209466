@import '../../variables.scss';

.user-bookings {
  width: 100%;
  box-sizing: border-box;
  padding: 0 8px;

  .pending-bookings,
  .confirmed-bookings {
    margin-bottom: 2rem;
    
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1rem;
      color: $text-color;
    }
  }

  .cancel-button {
    background-color: #fee2e2;
    color: #991b1b;
    border: none;
    width: 100px !important;
    margin-top: 8px;
    border-radius: 6px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #fecaca;
    }

    &:active {
      background-color: #fca5a5;
    }
  }

  .pending-bookings {
    .booking-card {
      border-left: 4px solid #f0b429; // Жёлтый цвет для ожидающих броней
      
      .status.pending {
        background-color: #fef3c7;
        color: #92400e;
        padding: 0.25rem 0.75rem;
        border-radius: 1rem;
        font-size: 0.875rem;
      }
    }
  }

  .loading, .no-bookings {
    text-align: center;
    padding: 40px;
    background-color: $secondary-bg-color;
    border-radius: 16px;
    color: $text-color;
  }

  .date-group {
    margin-bottom: 24px;

    .date-header {
      font-size: 14px;
      color: $hint-color;
      margin: 0 0 8px 0;
    }
  }

  .booking-card {
    background: $secondary-bg-color;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;

    .booking-info {
      width: 100%;

      .room-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        h3 {
          margin: 0;
          font-size: 18px;
          color: $text-color;
        }

        .status {
          font-size: 14px;
          padding: 4px 8px;
          border-radius: 4px;

          &.confirmed {
            color: #98CB4C;
            background-color: rgba(152, 203, 76, 0.1);
          }

          &.pending {
            color: #FFB800;
            background-color: rgba(255, 184, 0, 0.1);
          }

          &.cancelled {
            color: #FF4444;
            background-color: rgba(255, 68, 68, 0.1);
          }
        }
      }

      .booking-details {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 14px;
        color: $text-color;

        .time {
          color: $hint-color;
        }

        .type {
          color: $hint-color;
        }

        .people {
          color: $hint-color;
        }

        .event-type {
          color: $hint-color;
          font-style: italic;
        }

        .price {
          color: #98CB4C;
          font-weight: 500;
          margin-top: 4px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .user-bookings {
    .booking-card {
      .booking-info {
        .room-info {
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

          .status {
            align-self: flex-start;
          }
        }
      }
    }
  }
}

.booking-card {
  background-color: $bg-color;
}

.cancel-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cancel-modal {
  background-color: $bg-color;
  padding: 24px;
  border-radius: 16px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  
  p {
    font-size: 18px;
    text-align: center;
    margin-bottom: 24px;
    color: $text-color;
  }
  
  .cancel-modal-actions {
    display: flex;
    gap: 12px;
    
    button {
      flex: 1;
      padding: 12px;
      border: none;
      border-radius: 8px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.2s;
    }
    
    .confirm-cancel-button {
      background-color: #ff4444;
      color: white;
      
      &:hover {
        background-color: #cc3333;
      }
    }
    
    .cancel-cancel-button {
      background-color: $secondary-bg-color;
      color: $text-color;
      
      &:hover {
        opacity: 0.9;
      }
    }
  }
}