@import '../../../../variables.scss';

.uploader {
  width: 100%;
  
  .imageContainer {
    width: 160px;
    height: 160px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    
    &:hover .overlay {
      opacity: 1;
    }
    
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.2s ease;
      
      span {
        color: white;
        font-size: 14px;
        padding: 8px 16px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 4px;
      }
    }
  }
  
  .error {
    color: #f44336;
    font-size: 14px;
    margin-top: 8px;
  }
}