.switch {
    position: relative;
    display: flex;
    align-items: center;
    background-color: rgba(125, 125, 125, 0.25);
    border-radius: 12px;
    box-sizing: border-box;
    padding: 4px;
    width: 100%;
    min-height: 48px;
    overflow: hidden;
  
    .slider {
      position: absolute;
      box-sizing: border-box;
      top: 4px;
      left: 4px;
      bottom: 4px;
      background-color: var(--tg-theme-button-color);
      border-radius: 8px;
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      z-index: 1;
    }
  
    .option {
      position: relative;
      flex: 1;
      height: 40px;
      border: none;
      background: none;
      padding: 8px 8px;
      color: var(--tg-theme-text-color);
      font-size: 17px;
      font-weight: 500;
      cursor: pointer;
      transition: color 0.2s ease;
      z-index: 2;
  
      &.active {
        color: var(--tg-theme-button-text-color);
      }
  
      &:hover:not(.active):not(:disabled) {
        color: rgba(var(--tg-theme-text-color), 0.8);
      }
    }
  
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
  
      .option {
        cursor: not-allowed;
      }
  
      .slider {
        background-color: rgba(125, 125, 125, 0.5);
      }
    }
  }