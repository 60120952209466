.upperButton {
    display: inline-flex;
    padding: 8px 12px 8px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    backdrop-filter: blur(4px);
    font-size: 12px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: opacity 0.2s ease;
  
    &:hover {
      opacity: 0.9;
    }
  
    &.dark {
      background: rgba(0, 0, 0, 0.75);
      color: white;
    }
  
    &.white {
      background: rgba(207, 207, 207, 0.75);
      color: #6C6C6C;
    }
  
    .icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
  
      img {
        width: 100%;
        height: 100%;
      }
    }
  
    .label {
      line-height: 1;
    }
  }