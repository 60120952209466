.tabButton {
    backdrop-filter: blur(8px);
    border-radius: 12px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: auto;
    flex-shrink: 0;
  
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      transition: background 0.2s ease-in-out;
    }
  
    &:hover::before {
      background: rgba(255, 255, 255, 0.1);
    }
  
    &:active::before {
      background: rgba(255, 255, 255, 0.2);
    }
  
    .label {
      font-size: 16px;
      font-weight: 500;
      position: relative;
      white-space: nowrap;
      z-index: 1;
    }
  
    &.new-kid {
      background-color: rgba(37, 32, 78, 0.75);
      color: #ffffff;
  
      &:hover {
        background-color: rgba(37, 32, 78, 0.85);
      }
    }
  
    &.inactive-tab {
      background-color: rgba(0, 0, 0, 0.4);
      color: #ffffff;
  
      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  
    &.active-tab {
      background-color: rgba(0, 0, 0, 0.75);
      color: #ffffff;
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.85);
      }
    }
  
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
  
      &:hover::before,
      &:active::before {
        background: transparent;
      }
    }
  }