@import '../../../../../variables.scss';

.groupsContainer {
  padding: 20px;
  box-sizing: border-box;
  
  .buttonsContainer {
    display: flex;
    gap: 16px;
    padding: 12px;
    margin-bottom: 16px;
  }
  
  .sectionTitle {
    font-size: 24px;
    font-weight: 600;
    color: $text-color;
    margin: 0 0 16px 8px;
  }
  
  .errorContainer {
    text-align: center;
    padding: 40px 20px;
    
    .errorMessage {
      color: $hint-color;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  
  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    
    .loadingSpinner {
      border: 4px solid $secondary-bg-color;
      border-top: 4px solid $button-color;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
    }
  }

  .emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $secondary-bg-color;
    border-radius: 16px;
    padding: 40px 20px;
    margin: 20px auto;
    max-width: 500px;
    
    .emptyStateTitle {
      font-size: 18px;
      font-weight: 600;
      color: $text-color;
      margin-bottom: 8px;
    }
    
    .emptyStateText {
      color: $hint-color;
      margin-bottom: 24px;
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}