.landing-page {
    min-height: 100vh;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: white;
  
    .header {
      text-align: center;
      margin-bottom: 2rem;
  
      h1 {
        font-family: 'Balsamiq Sans', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
        color: #FF4500;
        line-height: 1.2;
        margin-bottom: -8px;
  
        @media (min-width: 1024px) {
          font-size: 3rem;
        }
      }
  
      h2 {
        font-family: 'Balsamiq Sans', sans-serif;
        font-weight: 700;
        font-size: 1.5rem;
        margin-top: -8px;
        color: #333333;
        line-height: 1.2;
  
        @media (min-width: 1024px) {
          font-size: 1.875rem;
        }
      }
    }
  
    .nav-buttons {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      max-width: 28rem;
      margin-bottom: 2rem;
      box-sizing: border-box;
  
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 1rem;
        border-radius: 0.5rem;
        font-family: 'Balsamiq Sans', sans-serif;
        font-weight: 700;
        transition: background-color 0.2s ease;
        width: 100%;
        box-sizing: border-box;
  
        &.vk-button {
          background-color: #0077FF;
          color: white;
  
          &:hover {
            background-color: #0066CC;
          }
  
          img {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
  
        &.booking-button {
          background-color: #4A4A4A;
          color: white;
  
          &:hover {
            background-color: #333333;
          }
        }
      }
    }
  
    .footer {
      text-align: center;
      color: #666;
  
      address {
        margin-bottom: 1.5rem;
        font-style: normal;
  
        p {
          margin-bottom: 0.5rem;
  
          &:first-child {
            font-weight: 600;
          }
        }
  
        a {
          color: inherit;
          text-decoration: none;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
  
      // Скрытый список услуг для SEO
      .services-list {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    }
  }