@import '../../variables.scss';

.user-switcher-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 16px;
  background: linear-gradient(
    to top,
    $bg-color 70%,
    rgba($bg-color, 0)
  );
}

.user-switcher-button {
  width: 100%;
  padding: 16px;
  background-color: $button-text-color-opacity;
  border: none;
  border-radius: 12px;
  color: $accent-text-color;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 8px;

  &:hover {
    opacity: 0.9;
  }

  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.user-list {
  background-color: $secondary-bg-color;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow: hidden;
  max-height: 300px;
  overflow-y: auto;

  .user-item {
    width: 100%;
    padding: 12px 16px;
    border: none;
    background: none;
    text-align: left;
    color: $text-color;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $button-text-color-opacity;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    & + .user-item {
      border-top: 1px solid rgba($hint-color, 0.1);
    }
  }
}