@import 'variables.scss';

html {
  background-color: $secondary-bg-color;
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  min-height: 97vh;
  background-color: $secondary-bg-color;
  padding: 0px;
  overflow-y: none;
  transition: background-color 0.3s ease;

  &.expanded {
    padding: 0;
    background-color: $bg-color;
  }
}

.auth-card {
  background: $bg-color;
  padding: 2rem;
  border-radius: 0px 0px 24px 24px;
  width: 100%;
  max-width: 400px;
  justify-content: center;
  transition: all 0.3s ease;

  &.expanded {
    padding: 12px;
    border-radius: 0;
    max-width: 100%;
  }

  .admin-button {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: $button-color;
    color: $button-text-color;
    border: none;
    border-radius: 12px;
    font-size: 1rem;
    cursor: pointer;
    transition: opacity 0.1s;

    &:hover {
      opacity: 0.9;
    }

    &.logout {
      margin-top: 2rem;
    }
  }

  h1 {
    margin: 0 0 1.5rem;
    font-size: 1.5rem;
    color: $text-color;
    font-weight: 600;
    text-align: center;
  }

  .input-group {
    margin-bottom: 1.5rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
      color: $hint-color;
    }

    input {
      width: 100%;
      padding: 0.75rem;
      border: none;
      border-radius: 6px;
      font-size: 1rem;
      background-color: $secondary-bg-color;
      box-sizing: border-box;
      color: $text-color;

      &::placeholder {
        color: $hint-color;
      }
    }
  }

  button {
    width: 100%;
    padding: 0.75rem;
    background-color: $button-color;
    color: $button-text-color;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    transition: opacity 0.1s ease-in-out, scale 0.1s ease-in-out;

    &:hover {
      opacity: 0.85;
      scale: 0.99;
    }
  }

  p {
    text-align: center;
    margin: 1rem 0;
    color: $hint-color;
  }
}