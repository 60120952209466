@import '../../variables.scss';


.home-screen {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
  
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 72px 0 32px;
      position: relative;
      z-index: 2;
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
    }
  
    .greeting-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .settingsButton {
        position: absolute;
        top: 8px;
        right: 8px;
      }

      .balanceButton {
        position: absolute;
        top: 8px;
        left: 8px;
      }
  
      .greeting-text {
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(8px);
        border-radius: 16px;
        padding: 4px 12px;
        color: $button-text-color;
        font-size: 14px;
      }
  
      .user-name {
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(8px);
        border-radius: 16px;
        padding: 4px 12px;
        color: $button-text-color;
        font-size: 20px;
        font-weight: 700;
        transform: rotate(-3deg);
        margin-top: -8px;
      }
    }
  
    .main-block {
      display: flex;
      position: sticky;
      height: 100%;
      flex-direction: column;
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      gap: 8px;
      position: relative;
      z-index: 1;
    }
  
    .tabs {
      display: flex;
      overflow-x: auto;
      gap: 8px;
      padding: 0 0 0 16px;
      width: 100%;
      box-sizing: border-box;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  
    .content {
      flex: 1;
      background-color: $bg-color;
      min-height: 60vh;
      height: 100%;
      border-radius: 24px 24px 0 0;
      width: 100%;
    }
  
    .background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 240px;
      background-size: cover;
      background-position: center;
      z-index: -5;

    @media (min-width: 1000px) {
        height: 100vh;
    }
  }
}