:root {
    --tg-theme-bg-color: #ffffff;
    --tg-theme-secondary-bg-color: #f2f2f7;
    --tg-theme-section-bg-color: #ffffff;
    --tg-theme-header-bg-color: #fafafa;
    --tg-theme-button-color: #80bf33;
    --tg-theme-button-text-color: #ffffff;
    --tg-theme-text-color: #000000;
    --tg-theme-hint-color: #8a8a8f;
    --tg-theme-accent-text-color: #66a31f;
    --tg-theme-section-separator-color: #d4d4d4;
    --tg-theme-button-text-color-opacity: rgba(128, 191, 51, 0.161);
  }
  
  // Переменные SCSS для использования в файлах стилей
  $bg-color: var(--tg-theme-bg-color);
  $secondary-bg-color: var(--tg-theme-secondary-bg-color);
  $section-bg-color: var(--tg-theme-section-bg-color);
  $header-bg-color: var(--tg-theme-header-bg-color);
  $button-color: var(--tg-theme-button-color);
  $button-text-color: var(--tg-theme-button-text-color);
  $text-color: var(--tg-theme-text-color);
  $hint-color: var(--tg-theme-hint-color);
  $accent-text-color: var(--tg-theme-accent-text-color);
  $section-separator-color: var(--tg-theme-section-separator-color);
  $button-text-color-opacity: var(--tg-theme-button-text-color-opacity)