@import '../../../../../variables.scss';

.employees {
    padding: 20px;
  
    .addEmployee {
      margin-bottom: 24px;
      display: flex;
      gap: 8px;
      align-items: flex-end;
  
      .phoneInput {
        flex: 1;
      }
  
      .buttons {
        display: flex;
        gap: 8px;
      }
    }
  
    .table {
      width: 100%;
      border-collapse: collapse;
      background: $secondary-bg-color;
      border-radius: 12px;
      overflow: hidden;
  
      th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid $section-separator-color;
      }
  
      th {
        background: $section-bg-color;
        color: $hint-color;
        font-weight: normal;
        font-size: 14px;
      }
  
      td {
        color: $text-color;
      }
  
      tr:last-child td {
        border-bottom: none;
      }
    }
  
    .noEmployees {
      text-align: center;
      padding: 40px;
      color: $hint-color;
    }
  }