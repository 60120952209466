@import '../../variables.scss';

.not-found-container {
  margin: auto;
  color: var(--tg-theme-text-color);
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
}

.back-home-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: var(--tg-theme-button-color);
  color: var(--tg-theme-button-text-color);
  border: none;
  border-radius: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}