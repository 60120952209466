@import '../../../../variables.scss';

.button {
  backdrop-filter: blur(16px);
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 48px;
  padding: 9px 24px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    border-radius: inherit;
    transition: background-color 0.2s ease;
  }

  &:hover:not(:disabled)::before {
    background-color: $button-text-color-opacity;
  }

  &:active:not(:disabled)::before {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.withIcon {
    padding-left: 16px;
    padding-right: 16px;
  }

  .label {
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    position: relative;
    z-index: 1;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  // State styles
  &.agressive {
    background-color: rgba(208, 44, 44, 0.15);

    .label {
      color: #fe5454;
    }

    .icon {
      color: #fe5454;
    }

    &:hover:not(:disabled) {
      background-color: rgba(208, 44, 44, 0.25);
    }
  }

  &.active {
    background-color: $button-color;

    .label {
      color: $button-text-color;
    }

    .icon {
      color: $button-text-color;
    }

    &:hover:not(:disabled) {
      background-color: $button-color;
    }
  }

  &.unnoticeable {
    background-color: $bg-color;

    .label {
      color: #e24444;
    }

    .icon {
      color: #e24444;
    }

    &:hover:not(:disabled) {
      background-color: $bg-color;
    }
  }

  &.inactive {
    background-color: #99898e;

    .label {
      color: $button-text-color;
    }

    .icon {
      color: $button-text-color;
    }

    &:hover:not(:disabled) {
      background-color: darken(#99898e, 5%);
    }
  }

  &.secondary {
    background-color: $button-text-color-opacity;

    .label {
      color: $accent-text-color;
    }

    .icon {
      color: $accent-text-color;
    }

    &:hover:not(:disabled) {
      background-color: rgba($button-text-color-opacity, 0.3);
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &::before {
      display: none;
    }
  }
}

@media (max-width: 480px) {
  .button {
    width: 100%;
    max-width: none;
  }
}