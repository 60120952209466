@import '../../../variables.scss';

.authCard {
  background: var(--tg-theme-bg-color);
  padding: 2rem;
  width: 100%;
  height: 95vh;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  .greetingIcon {
    width: 200px !important;
    height: 200px !important;
  }

  h1 {
    margin: 0 0 1.5rem;
    font-size: 1.5rem;
    color: var(--tg-theme-text-color);
    font-weight: 600;
    text-align: center;
  }

  .errorMessage {
    width: 100%;
    max-width: 420px;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: rgba(255, 68, 68, 0.1);
    color: #ff4444;
    border-radius: 8px;
    text-align: center;
    font-size: 0.875rem;
  }

  .inputGroup {
    width: 100%;
    max-width: 420px;
    margin-bottom: 8px;
  }

  .buttons {
    width: 100%;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .button {
      width: 100%;
    }
  }
}

@media (max-width: 480px) {
  .authCard {
    padding: 1rem;

    .greetingIcon {
      width: 120px;
      height: 120px;
    }

    h1 {
      font-size: 1.25rem;
    }
  }
}