@import '../../../../variables.scss';

.lessonCard {
  width: 200px;
  border-radius: 12px;
  overflow: hidden;
  background-color: $secondary-bg-color;
  display: flex;
  flex-direction: column;

  .imageContainer {
    position: relative;
    width: 100%;
    height: 200px;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .imagePlaceholder {
      width: 100%;
      height: 100%;
      background-color: #a5a5a5; // Blue background as in the example
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      
      .dateTimeSection, .infoSection {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      
      .dateBlock, .timeBlock {
        display: inline-block;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        color: white;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-backdrop-filter: blur(32px);
        backdrop-filter: blur(32px);
        max-width: fit-content;
      }
      
      .ageBlock, .priceBlock {
        display: inline-block;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 600;
        color: black;
        background-color: rgba(255, 255, 255, 0.5);
        -webkit-backdrop-filter: blur(32px);
        backdrop-filter: blur(32px);
        max-width: fit-content;
      }

      .priceBlock {
        font-size: 16px;
        font-weight: 700;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .content {
    padding: 12px;
    flex-grow: 1;

    .title {
      margin: 0 0 4px 0;
      font-size: 16px;
      font-weight: 600;
      color: $text-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .studentLimit {
      margin: 0 0 4px 0;
      font-size: 14px;
      color: $hint-color;
    }

    .description {
      margin: 0;
      font-size: 14px;
      color: $text-color;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1.3;
    }
  }

  .footer {
    padding: 0 12px 12px 12px;

    .footerButton {
      width: 100%;
      border-radius: 8px;
    }
  }
}