@import '../../../../variables.scss';

.settingsPage {
  width: 100%;
  min-height: 100vh;
  padding: 14px;
  background-color: var(--tg-theme-bg-color);
  box-sizing: border-box;

  .header {
    position: relative;
    margin-bottom: 24px;
    
    h1 {
      font-size: 28px;
      font-weight: 600;
      color: var(--tg-theme-text-color);
      margin-top: 40px;
    }

    .backButton {
      position: fixed;
      top: 12px;
      left: 12px;
      z-index: 100;
    }
  }

  .section {
    margin-bottom: 32px;

    h2 {
      font-size: 20px;
      font-weight: 600;
      color: var(--tg-theme-text-color);
      margin: 0 0 16px 0;
    }

    .inputGroup {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .disabledInput {
    opacity: 0.5;
    pointer-events: none;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;
  }
}