@import '../../../../../variables.scss';

.studentsContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;
    gap: 12px;
    
    .searchBox {
      flex: 1;
      min-width: 300px;
    }
    
    .filterButtons {
      display: flex;
      gap: 8px;
      
      .filterButton {
        padding: 8px 16px;
        border: none;
        border-radius: 8px;
        background-color: $secondary-bg-color;
        color: $text-color;
        cursor: pointer;
        transition: all 0.2s ease;
        
        &:hover {
          background-color: $secondary-bg-color;
        }
        
        &.active {
          background-color: $button-color;
          color: $button-text-color;
        }
      }
    }
  }
  
  .tableContainer {
    overflow-x: auto;
    border-radius: 12px;
    background-color: $secondary-bg-color;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    
    .studentsTable {
      width: 100%;
      border-collapse: collapse;
      
      th, td {
        padding: 12px 16px;
        text-align: left;
        border-bottom: 1px solid $section-separator-color;
      }
      
      th {
        font-weight: 600;
        color: $hint-color;
        font-size: 14px;
        position: sticky;
        top: 0;
        background-color: $secondary-bg-color;
        z-index: 1;
      }
      
      .expandColumn {
        width: 40px;
      }
      
      .balanceColumn, .ageColumn, .birthDateColumn, .statusColumn {
        width: 14%;
      }
      
      .actionsColumn {
        width: 16%;
      }
      
      tbody {
        tr:last-child td {
          border-bottom: none;
        }
        
        .noData {
          text-align: center;
          padding: 24px;
          color: $hint-color;
        }
      }
      
      .studentRow {
        background-color: $secondary-bg-color;
        transition: background-color 0.2s ease;
        color: $text-color;
        
        &:hover {
          background-color: $secondary-bg-color;
        }
        
        .expandButton {
          width: 28px;
          height: 28px;
          border: none;
          border-radius: 4px;
          background-color: $section-separator-color;
          color: $text-color;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.2s ease;
          
          &:hover {
            background-color: $button-color;
            color: $button-text-color;
          }
        }

        .expandCell {
          width: 40px;
        }
        
        .nameCell {
          .studentName {
            font-weight: 500;
            color: $text-color;
          }
          
          .studentPhone {
            font-size: 14px;
            color: $hint-color;
            margin-top: 4px;
          }
        }
        
        .balanceCell {
          font-weight: 600;
          
          .positiveBalance {
            color: #4CAF50;
          }
          
          .negativeBalance {
            color: #f44336;
          }
        }
        
        .statusCell {
          cursor: pointer;
        }
        
        .statusBadge {
          display: inline-block;
          padding: 4px 10px;
          border-radius: 12px;
          font-size: 14px;
          
          &.active {
            background-color: rgba(76, 175, 80, 0.1);
            color: #4CAF50;
          }
          
          &.inactive {
            background-color: rgba(244, 67, 54, 0.1);
            color: #f44336;
          }
          
          &.pending {
            background-color: rgba(255, 167, 38, 0.1);
            color: #FF9800;
          }
        }
        
        .actionButtons {
          display: flex;
          gap: 8px;
          flex-wrap: wrap;
          
          .editButton {
            padding: 6px 12px;
            border: none;
            border-radius: 6px;
            font-size: 14px;
            cursor: pointer;
            transition: all 0.2s ease;
            background-color: $button-text-color-opacity;
            color: $accent-text-color;
            
            &:hover {
              background-color: $button-text-color-opacity;
            }
          }
        }
      }
      
      .detailsRow {
        background-color: $bg-color;
        color: $text-color;
        
        .detailsContainer {
          padding: 16px;
          
          h3 {
            font-size: 18px;
            font-weight: 600;
            margin: 0 0 16px 0;
            color: $text-color;
          }
          
          .studentDetails {
            margin-bottom: 24px;
            
            .detailsGrid {
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
              gap: 24px;
              background-color: $secondary-bg-color;
              border-radius: 12px;
              padding: 16px;
              
              .detailGroup {
                h4 {
                  font-size: 16px;
                  font-weight: 600;
                  margin: 0 0 12px 0;
                  color: $text-color;
                }
                
                p {
                  margin: 8px 0;
                  color: $text-color;
                  font-size: 14px;
                  
                  strong {
                    color: $hint-color;
                    font-weight: normal;
                    margin-right: 4px;
                  }
                }
                
                ul {
                  margin: 8px 0 8px 24px;
                  padding: 0;
                  
                  li {
                    margin-bottom: 4px;
                  }
                }
                
                .permissionBadge {
                  display: inline-block;
                  padding: 2px 8px;
                  border-radius: 10px;
                  font-size: 13px;
                  margin-left: 4px;
                  
                  &.allowed {
                    background-color: rgba(76, 175, 80, 0.1);
                    color: #4CAF50;
                  }
                  
                  &.partial {
                    background-color: rgba(255, 167, 38, 0.1);
                    color: #FF9800;
                  }
                  
                  &.denied {
                    background-color: rgba(244, 67, 54, 0.1);
                    color: #f44336;
                  }
                }
              }
            }
          }
          
          .parentInfo {
            .parentCard {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: $secondary-bg-color;
              border-radius: 12px;
              padding: 16px;
              
              .parentData {
                .parentName {
                  font-size: 16px;
                  font-weight: 500;
                  margin: 0 0 4px 0;
                }
                
                .parentPhone {
                  font-size: 14px;
                  color: $hint-color;
                  margin: 0 0 8px 0;
                }
                
                .parentBalance, .parentStatus {
                  font-size: 14px;
                  margin: 4px 0;
                  
                  .positiveBalance {
                    color: #4CAF50;
                    margin-left: 4px;
                    font-weight: 500;
                  }
                  
                  .negativeBalance {
                    color: #f44336;
                    margin-left: 4px;
                    font-weight: 500;
                  }
                  
                  .statusBadge {
                    display: inline-block;
                    padding: 2px 8px;
                    border-radius: 10px;
                    font-size: 13px;
                    margin-left: 4px;
                    
                    &.active {
                      background-color: rgba(76, 175, 80, 0.1);
                      color: #4CAF50;
                    }
                    
                    &.inactive {
                      background-color: rgba(244, 67, 54, 0.1);
                      color: #f44336;
                    }
                    
                    &.pending {
                      background-color: rgba(255, 167, 38, 0.1);
                      color: #FF9800;
                    }
                  }
                }
              }
              
              .parentActions {
                flex-shrink: 0;
              }
            }
          }
        }
      }
    }
  }
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
  
  .loadingSpinner {
    border: 4px solid $secondary-bg-color;
    border-top: 4px solid $button-color;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
  
  p {
    color: $hint-color;
    font-size: 16px;
  }
}

.statusEditContent {
  .currentValueLabel {
    margin: 0 0 16px 0;
    color: $hint-color;
    font-size: 14px;
  }
}

.statusSwitch {
  width: 100%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1024px) {
  .studentsContainer {
    .tableContainer {
      .studentsTable {
        .studentRow {
          .actionButtons {
            flex-direction: column;
            
            button {
              width: 100%;
            }
          }
        }
        
        .detailsRow {
          .detailsContainer {
            .parentInfo {
              .parentCard {
                flex-direction: column;
                align-items: flex-start;
                
                .parentData {
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .studentsContainer {
    .controls {
      flex-direction: column;
      align-items: stretch;
      
      .searchBox {
        width: 100%;
      }
      
      .filterButtons {
        width: 100%;
        justify-content: space-between;
        overflow-x: auto;
      }
    }
  }
}