@import '../../../../../variables.scss';

.lessonsContainer {
  padding: 0px;
  width: 100%;
  box-sizing: border-box;
  
  .buttonsContainer {
    display: flex;
    gap: 16px;
    padding: 12px;
  }
  
  .sectionTitle {
    font-size: 24px;
    font-weight: 600;
    color: $text-color;
    margin: 0 0 8px 24px;
  }
  
  .lessonsScroll {
    display: flex;
    overflow-x: auto;
    gap: 16px;
    padding: 0 16px 12px 16px;
    scrollbar-width: none; /* Скрывает скроллбар в Firefox */
    -ms-overflow-style: none; /* Скрывает скроллбар в Internet Explorer и Edge */
    &::-webkit-scrollbar {
      display: none;
    }
    
    .lessonCardWrapper {
      flex: 0 0 auto;
    }
  }
  
  .loading, .noLessons {
    padding: 20px;
    text-align: center;
    color: $hint-color;
    font-size: 16px;
  }
}

// Create Lesson Form Styles
.createLessonForm {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  margin: 0 auto;
  
  .formTitle {
    font-size: 24px;
    font-weight: 600;
    color: $text-color;
    margin: 8px 0 16px 16px;
  }

  .formMainSection {
    display: inline-flex;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 16px;
    flex-wrap: wrap;
    background-color: $secondary-bg-color;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 12px;
    width: 100%;

    .formColumn {
      flex: 1;
      position: relative;
    }

    .formHalfColumn {
      flex: 1;
      position: relative;
      min-width: 280px;
    }
  }
  
  .formSection {
    background-color: $secondary-bg-color;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 24px;
    
    .sectionTitle {
      font-size: 18px;
      font-weight: 600;
      color: $text-color;
      margin: 0 0 16px 0;
    }
  }
  
  .formRow {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    .formColumn {
      flex: 1;
      position: relative;
    }
  }
  
  .textarea {
    width: 100%;
    min-height: 100px;
    padding: 12px;
    box-sizing: border-box;
    border: 1px solid $section-separator-color;
    border-radius: 8px;
    background-color: $bg-color;
    color: $text-color;
    font-family: inherit;
    font-size: 16px;
    resize: vertical;
    
    &::placeholder {
      color: $hint-color;
    }
    
    &:focus {
      outline: none;
      border-color: $button-color;
    }
    
    &:disabled {
      background-color: rgba($secondary-bg-color, 0.5);
      cursor: not-allowed;
    }
  }
  
  .selectLabel {
    display: block;
    font-size: 12px;
    color: $hint-color;
    margin: 0 0 4px 8px;
  }
  
  .select {
    width: 100%;
    height: 48px;
    padding: 0 12px;
    border: 1px solid $section-separator-color;
    border-radius: 8px;
    background-color: $bg-color;
    color: $text-color;
    font-size: 16px;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%238a8a8f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
    
    &:focus {
      outline: none;
      border-color: $button-color;
    }
    
    &:disabled {
      background-color: rgba($secondary-bg-color, 0.5);
      cursor: not-allowed;
    }
  }
  
  .errorMessage {
    color: #f44336;
    font-size: 12px;
    margin-top: 4px;
    margin-left: 8px;
  }
  
  .formButtons {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 20px;
    
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
}