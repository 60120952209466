@import '../../../../../variables.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.popup {
  background-color: $bg-color;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.2s ease;
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 12px;
    border-bottom: 1px solid $section-separator-color;
    
    h3 {
      margin: 0;
      font-size: 20px;
      font-weight: 600;
      color: $text-color;
    }
    
    .closeButton {
      background: none;
      border: none;
      font-size: 24px;
      color: $hint-color;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      transition: all 0.2s ease;
      
      &:hover {
        background-color: $secondary-bg-color;
        color: $text-color;
      }
    }
  }
  
  .content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .reasonSection {
    padding: 0 24px 24px;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 0 24px 24px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 520px) {
  .popup {
    width: 95%;
    max-height: 90vh;
    overflow-y: auto;
    
    .actions {
      flex-direction: column-reverse;
      
      button {
        width: 100%;
      }
    }
  }
}