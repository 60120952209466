@import '../../../../../variables.scss';

.clientsContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;
    gap: 12px;
    
    .searchBox {
      flex: 1;
      min-width: 300px;
    }
    
    .filterButtons {
      display: flex;
      gap: 8px;
      
      .filterButton {
        padding: 8px 16px;
        border: none;
        border-radius: 8px;
        background-color: $secondary-bg-color;
        color: $text-color;
        cursor: pointer;
        transition: all 0.2s ease;
        
        &:hover {
          background-color: $secondary-bg-color;
        }
        
        &.active {
          background-color: $button-color;
          color: $button-text-color;
        }
      }
    }
  }
  
  .tableContainer {
    overflow-x: auto;
    border-radius: 12px;
    background-color: $secondary-bg-color;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    
    .clientsTable {
      width: 100%;
      border-collapse: collapse;
      
      th, td {
        padding: 12px 16px;
        text-align: left;
        border-bottom: 1px solid $section-separator-color;
      }
      
      th {
        font-weight: 600;
        color: $hint-color;
        font-size: 14px;
        position: sticky;
        top: 0;
        background-color: $secondary-bg-color;
        z-index: 1;
      }
      
      .expandColumn {
        width: 40px;
      }
      
      .balanceColumn, .phoneColumn, .statusColumn {
        width: 17%;
      }
      
      .addressColumn {
        width: 20%;
      }
      
      .actionsColumn {
        width: 20%;
      }
      
      tbody {
        tr:last-child td {
          border-bottom: none;
        }
        
        .noData {
          text-align: center;
          padding: 24px;
          color: $hint-color;
        }
      }
      
      .parentRow {
        background-color: $secondary-bg-color;
        transition: background-color 0.2s ease;
        
        &:hover {
          background-color: $secondary-bg-color;
        }
        
        .expandButton {
          width: 28px;
          height: 28px;
          border: none;
          border-radius: 4px;
          background-color: $section-separator-color;
          color: $text-color;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.2s ease;
          
          &:hover {
            background-color: $button-color;
            color: $button-text-color;
          }
        }

        .expandCell {
          width: 40px;
        }
        
        .nameCell {
          .parentName {
            font-weight: 500;
            color: $text-color;
          }
          
          .parentPhone {
            font-size: 14px;
            color: $hint-color;
            margin-top: 4px;
          }
        }
        
        .balanceCell {
          font-weight: 600;
          cursor: pointer;
          
          .positiveBalance {
            color: #4CAF50;
          }
          
          .negativeBalance {
            color: #f44336;
          }
        }
        
        .statusCell {
          cursor: pointer;
        }
        
        .statusBadge {
          display: inline-block;
          padding: 4px 10px;
          border-radius: 12px;
          font-size: 14px;
          
          &.active {
            background-color: rgba(76, 175, 80, 0.1);
            color: #4CAF50;
          }
          
          &.inactive {
            background-color: rgba(244, 67, 54, 0.1);
            color: #f44336;
          }
          
          &.pending {
            background-color: rgba(255, 167, 38, 0.1);
            color: #FF9800;
          }
        }
        
        .actionButtons {
          display: flex;
          gap: 8px;
          flex-wrap: wrap;
          
          .editButton {
            padding: 6px 12px;
            border: none;
            border-radius: 6px;
            font-size: 14px;
            cursor: pointer;
            transition: all 0.2s ease;
            background-color: $button-text-color-opacity;
            color: $accent-text-color;
            
            &:hover {
              background-color: $button-text-color-opacity;
            }
          }

          .resetPasswordButton {
            color: #fe5454;
            background-color: rgba(208, 44, 44, 0.15);

            &:hover {
              background-color: rgba(208, 44, 44, 0.10);
            }
          }
        }
      }
      
      .childrenRow {
        background-color: $bg-color;
        
        .childrenContainer {
          padding: 16px;
          
          .extraParentInfo {
            background-color: $secondary-bg-color;
            border-radius: 8px;
            padding: 16px;
            margin-bottom: 16px;
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            
            .infoGroup {
              flex: 1;
              min-width: 250px;
              
              h4 {
                font-size: 16px;
                font-weight: 600;
                margin: 0 0 12px 0;
                color: $text-color;
              }
              
              p {
                margin: 8px 0;
                color: $text-color;
                font-size: 14px;
                
                strong {
                  color: $hint-color;
                  font-weight: normal;
                  margin-right: 4px;
                }
              }
            }
          }
          
          .childrenHeader {
            font-size: 16px;
            font-weight: 600;
            margin: 0 0 12px 0;
            color: $text-color;
          }
          
          .noChildren {
            color: $hint-color;
            font-style: italic;
          }
          
          .childrenTable {
            width: 100%;
            border-collapse: collapse;
            margin-top: 8px;
            
            th, td {
              padding: 10px;
              text-align: left;
              border-bottom: 1px solid $section-separator-color;
            }

            tr {
              color: $text-color;
            }
            
            th {
              font-weight: 500;
              color: $hint-color;
              font-size: 14px;
            }
            
            .childRow {
              background-color: $secondary-bg-color;
              transition: background-color 0.2s ease;
              
              &:hover {
                background-color: $secondary-bg-color;
              }
              
              &:last-child td {
                border-bottom: none;
              }
              
              .childName {
                font-weight: 500;
              }
              
              .childActions {
                button {
                  padding: 4px 10px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
  
  .loadingSpinner {
    border: 4px solid $secondary-bg-color;
    border-top: 4px solid $button-color;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
  
  p {
    color: $hint-color;
    font-size: 16px;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1300px) {
  .clientsContainer {
    .tableContainer {
      .clientsTable {
        .parentRow {
          .actionButtons {
            flex-direction: column;
            
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .clientsContainer {
    .controls {
      flex-direction: column;
      align-items: stretch;
      
      .searchBox {
        width: 100%;
      }
      
      .filterButtons {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

// Стили для попапа редактирования
.balanceEditContent, .statusEditContent {
  .currentValueLabel {
    margin: 0 0 16px 0;
    color: $hint-color;
    font-size: 14px;
  }
}

.statusSwitch {
  width: 100%;
}