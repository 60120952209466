@import '../../variables.scss';

.back-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    background: $bg-color;
    padding: 8px 0 24px 0;
    border-top: 1px solid $secondary-bg-color;
    pointer-events: none; // Чтобы градиент не блокировал клики
  }
  
  .back-button-wrapper {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 16px;
    pointer-events: auto; // Возвращаем обработку кликов для кнопки
  }
  
  .back-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border: none;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    transition: opacity 0.2s ease;
  
    &:hover {
      opacity: 0.9;
    }
  
    &:active {
      opacity: 0.8;
    }
  }