@import '../../variables.scss';

.pin-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h1 {
    margin: 0 0 1.5rem;
    font-size: 20px;
    color: $text-color;
    font-weight: 600;
    text-align: center;
  }

  .digits-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
    width: 100%;
    max-width: 240px;
    padding: 0 8px;
    box-sizing: border-box;

    .digit-input {
      flex: 1;
      min-width: 0;
      height: 56px;
      border: 2px solid $secondary-bg-color;
      background-color: $secondary-bg-color;
      border-radius: 12px;
      text-align: center;
      font-size: 24px;
      color: $text-color;
      transition: all 0.2s ease;
      padding: 0;
      cursor: default;

      &:focus {
        outline: none;
        border: 2px solid $button-color;
      }

      &.current {
        border-color: $button-color;
      }

      &.error {
        border-color: #FF4444;
        animation: shake 0.5s;
      }
    }
  }

  .error-message {
    color: #FF4444;
    text-align: center;
    margin: 8px 0 16px;
    font-size: 14px;
  }

  .keypad {
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
    
    .keypad-row {
      display: flex;
      justify-content: center;
      gap: 8px;
      margin-bottom: 8px;

      .keypad-button {
        width: 64px;
        height: 64px;
        border: none;
        border-radius: 16px;
        background-color: $secondary-bg-color;
        color: $text-color;
        font-size: 24px;
        cursor: pointer;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover:not(:disabled) {
          background-color: rgba($button-color, 0.1);
        }

        &:active:not(:disabled) {
          transform: scale(0.95);
        }

        &.empty {
          visibility: hidden;
        }

        &.backspace {
          font-size: 20px;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(8px); }
  75% { transform: translateX(-8px); }
}

@media screen and (max-width: 360px) {
  .pin-input-container {
    .digits-container {
      gap: 4px;
      padding: 0 4px;

      .digit-input {
        font-size: 20px;
        height: 48px;
      }
    }

    .keypad {
      .keypad-row {
        .keypad-button {
          width: 56px;
          height: 56px;
          font-size: 20px;
        }
      }
    }
  }
}