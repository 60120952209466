@import '../../../../../variables.scss';

.childContentContainer {

  .loadingState, .errorState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    
    p {
      color: $hint-color;
      font-size: 16px;
      margin-bottom: 16px;
    }
  }

  .childHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .childInfo {
      flex: 1;
      min-width: 0; // Required for text-overflow to work
      overflow: hidden; // Ensure overflow is hidden
      margin-right: 12px; // Add margin to keep space from buttons

      .editIcon {
        margin-left: 8px;
        opacity: 0.4;
      }

      .childName {
        font-size: 24px;
        font-weight: 600;
        color: $text-color;
        margin: 0 0 4px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%; // Ensure it doesn't overflow its parent
        display: block; // Make sure it behaves as a block element

        &:hover{
          cursor: pointer;
          .editIcon {
            opacity: 0.6;
          }
        }
      }

      .childAge {
        color: $hint-color;
        font-size: 16px;
        margin: 0;
      }
    }

    .headerButtons {
      display: flex;
      gap: 8px;
      flex-shrink: 0; // Prevent buttons from shrinking
      flex-basis: auto; // Set a specific basis
    }
  }

  .infoCard {
    margin-bottom: 16px;

    h2 {
      font-size: 22px;
      font-weight: 600;
      color: $text-color;
      margin-top: 0;
      margin-left: 20px;
      margin-bottom: 12px;
    }
  }

  .lessonsScroll {
    display: flex;
    overflow-x: auto;
    gap: 16px;
    padding: 0 32px 0 16px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
    
    .lessonCardWrapper {
      flex: 0 0 auto;
    }
  }
  
  .noLessons {
    text-align: center;
    padding: 30px 0;
    color: $hint-color;
    font-style: italic;
  }

  /* Lesson Detail View Styles */
  .lessonDetailCard {
    background-color: $secondary-bg-color;
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 24px;
    
    .lessonTitle {
      font-size: 24px;
      font-weight: 600;
      color: $text-color;
      margin: 0 0 16px 0;
      text-align: center;
    }
    
    .lessonImageContainer {
      width: 100%;
      height: 200px;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 20px;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
      .imagePlaceholder {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #a5a5a5;
        color: white;
        font-size: 18px;
      }
    }
    
    .lessonDetailsGrid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 16px;
      margin-bottom: 20px;
      
      .detailItem {
        .detailLabel {
          color: $hint-color;
          font-size: 14px;
          margin: 0 0 4px 0;
        }
        
        .detailValue {
          color: $text-color;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
        }
      }
    }
    
    .lessonDescription {
      margin-bottom: 20px;
      
      h3 {
        font-size: 16px;
        font-weight: 600;
        color: $text-color;
        margin: 0 0 8px 0;
      }
      
      p {
        color: $text-color;
        font-size: 14px;
        line-height: 1.5;
        margin: 0;
      }
    }
    
    .actionButtons {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      
      @media (max-width: 480px) {
        flex-direction: column-reverse;
        
        button {
          width: 100%;
        }
      }
    }
  }

  .enrollmentsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;

    .noEnrollments {
      color: $hint-color;
      font-size: 16px;
      margin-bottom: 16px;
      text-align: center;
    }
  }
  
  .documentsScroll {
    display: flex;
    overflow-x: auto;
    gap: 16px;
    padding: 8px 0 16px;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    
    &::-webkit-scrollbar {
      height: 6px;
    }
    
    &::-webkit-scrollbar-track {
      background: rgba($hint-color, 0.1);
      border-radius: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba($hint-color, 0.3);
      border-radius: 6px;
    }
  }
  
  .documentCard {
    flex: 0 0 auto;
    width: 120px;
    
    .documentPreview {
      width: 120px;
      height: 120px;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 8px;
      cursor: pointer;
      transition: opacity 0.2s;
      
      &:hover {
        opacity: 0.9;
      }
      
      .previewPlaceholder {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f2f2f2;
        color: $hint-color;
        font-size: 18px;
        font-weight: 600;
      }
    }
    
    .documentName {
      font-size: 14px;
      color: $text-color;
      margin: 0 0 4px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    .documentDate {
      font-size: 12px;
      color: $hint-color;
      margin: 0;
    }
  }
}