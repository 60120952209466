@import '../../../../../variables.scss';

.formContainer {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  margin: 0 auto;
  
  .formTitle {
    font-size: 24px;
    font-weight: 600;
    color: $text-color;
    margin: 8px 0 16px 16px;
  }

  .formMainSection {
    display: inline-flex;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 16px;
    flex-wrap: wrap;
    background-color: $secondary-bg-color;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 12px;
    width: 100%;

    .formColumn {
      flex: 1;
      position: relative;
      min-width: 280px;
    }

    .fullWidth {
      width: 100%;
    }

    .sectionTitle {
      font-size: 18px;
      font-weight: 600;
      color: $text-color;
      margin: 0 0 16px 0;
    }
  }

  .templateInfoSection {
    display: flex;
    gap: 20px;
    background-color: $secondary-bg-color;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 16px;
    
    .templateCard {
      width: 200px;
      margin-right: 16px;
      
      .imageContainer {
        width: 200px;
        height: 200px;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 12px;
        
        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        
        .imagePlaceholder {
          width: 100%;
          height: 100%;
          background-color: #a5a5a5;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 18px;
        }
      }
    }
    
    .templateDetails {
      flex: 1;
      
      .templateName {
        font-size: 24px;
        font-weight: 600;
        margin: 0 0 12px 0;
        color: $text-color;
      }
      
      .detailRow {
        margin-bottom: 8px;
        display: flex;
        gap: 8px;
        align-items: center;
        
        .label {
          width: 140px;
          color: $hint-color;
          font-size: 14px;
        }
        
        .value {
          color: $text-color;
          font-weight: 500;
        }
      }

      .editButton {
        margin-top: 16px;
      }
    }
  }

  .daysLabel {
    font-size: 14px;
    color: $hint-color;
    margin: 0 0 12px 8px;
  }

  .daysGrid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px;
    margin-bottom: 16px;
    
    @media (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
    
    @media (max-width: 480px) {
      grid-template-columns: repeat(3, 1fr);
    }
    
    .dayButton {
      padding: 10px;
      border-radius: 8px;
      border: none;
      background-color: $bg-color;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease;
      
      &:hover {
        opacity: 0.85;
      }
      
      &.selected {
        background-color: $button-color;
        color: $button-text-color;
      }
      
      .dayShort {
        font-weight: 600;
        font-size: 16px;
      }
      
      .dayFull {
        font-size: 12px;
        margin-top: 4px;
      }
    }
  }

  .previewSection {
    background-color: $secondary-bg-color;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 16px;
    
    .sectionTitle {
      font-size: 18px;
      font-weight: 600;
      color: $text-color;
      margin: 0 0 16px 0;
    }
    
    .datesPreview {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 8px;
      
      .dateItem {
        display: flex;
        align-items: center;
        background-color: $bg-color;
        padding: 10px;
        border-radius: 8px;
        
        .dateNumber {
          margin-right: 8px;
          background-color: $button-color;
          color: $button-text-color;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 14px;
        }
        
        .dateValue {
          color: $text-color;
          font-size: 14px;
        }
      }
    }
    
    .noDates {
      padding: 16px;
      text-align: center;
      color: $hint-color;
      font-style: italic;
    }
  }

  .formButtons {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 32px;
    
    .leftButtons {
      display: flex;
      gap: 8px;
    }
    
    .rightButtons {
      display: flex;
      gap: 8px;
    }
    
    @media (max-width: 768px) {
      flex-direction: column;
      
      .leftButtons, .rightButtons {
        width: 100%;
      }
    }
  }

  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    text-align: center;
    color: $hint-color;
  }

  .errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;
    
    .errorMessage {
      color: $hint-color;
      margin-bottom: 20px;
    }
  }

  .formRow {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .formInputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .templateEditContainer {
    margin-top: 24px;
    width: 100%;
  }
}