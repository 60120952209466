@import '../../variables.scss';

.profile-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;

  .loading {
    text-align: center;
    padding: 40px;
    color: $hint-color;
    font-size: 18px;
  }

  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .tabs {
      display: flex;
      gap: 8px;
    }

    .tab-button {
      padding: 12px 24px;
      border: none;
      border-radius: 12px;
      font-size: 16px;
      cursor: pointer;
      transition: all 0.2s;

      &.active {
        background-color: $button-color;
        color: $button-text-color;
      }

      &:not(.active) {
        background-color: $button-text-color-opacity;
        color: $accent-text-color;
      }

      &:hover:not(.active) {
        background-color: $button-text-color-opacity;
      }
    }

    .logout-button {
      padding: 12px 24px;
      background-color: transparent;
      border: 1px solid $button-color;
      border-radius: 12px;
      color: $button-color;
      font-size: 16px;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background-color: $button-text-color-opacity;
      }
    }
  }

  .profile-content {
    h1 {
      font-size: 24px;
      color: $text-color;
      margin-bottom: 8px;
      font-weight: 600;
    }

    .phone-info {
      color: $hint-color;
      margin-bottom: 32px;
      font-size: 16px;
    }

    .my-bookings {
      text-align: center;
      padding: 40px;
      background-color: $secondary-bg-color;
      border-radius: 16px;
      color: $hint-color;
    }

    .no-rooms {
      text-align: center;
      padding: 40px;
      background-color: $secondary-bg-color;
      border-radius: 16px;
      color: $hint-color;
    }
  }

  .rooms-grid {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
    
    .room-card {
      display: flex;
      min-width: 150px;
      width: 156px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      background-color: $secondary-bg-color;
      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;
      margin: 0;
      transition: transform 0.2s, box-shadow 0.2s;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }

      .room-photo {
        width: 100%;
        max-width: 200px;
        aspect-ratio: 4/3;
        background-color: #969696;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .placeholder-photo {
          width: 100%;
          height: 100%;
          background-color: #969696;
        }
      }

      .room-info {
        width: 100%;
        box-sizing: border-box;
        padding: 4px 16px 4px 16px;

        h2 {
          font-size: 20px;
          color: $text-color;
          margin: 0 0 6px 0;
          font-weight: 600;
        }

        .price-info {
          width: 100%;
          margin-bottom: 12px;

          p {
            margin: 0;
          }

          .price {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;
            font-size: 14px;

            .label {
              color: $hint-color;
            }

            .amount {
              font-weight: 600;
              color: $text-color;
            }

            &.individual-price {
              opacity: 0.8;
            }
          }
        }

        .description {
          font-size: 14px;
          color: $hint-color;
          line-height: 1.4;
        }
      }
    }
  }
}

// Медиа-запросы для адаптивности
@media (max-width: 768px) {
  .profile-container {
    padding: 16px;

    .profile-header {
      flex-direction: column;
      gap: 8px;
      align-items: stretch;

      .tabs {
        justify-content: stretch;
      }

      .tab-button {
        flex: 1;
      }
    }
  }
}

@media (max-width: 480px) {
  .profile-container {
    padding: 12px;

    .profile-header {
      .tab-button {
        padding: 10px 16px;
        font-size: 14px;
      }

      .logout-button {
        padding: 10px 16px;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 444px) {
  .room-card {
    width: 178px;

    .room-info {
      width: 100%;
      box-sizing: border-box;
      padding: 2px 8px p2x 8px;
    }
  }
}

@media (max-width: 415px) {
  .room-card {
    width: 184px;
  }
}

@media (max-width: 399px) {
  .room-card {
    width: 158px;

    .room-info {
      width: 100%;
      box-sizing: border-box;
      padding: 2px 8px p2x 8px;
    }
  }
}

