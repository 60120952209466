@import '../../../../../variables.scss';

.addChildContainer {
  padding: 16px;
  
  h1 {
    margin: 8px 0px 12px 0px;
    font-size: 24px;
    font-weight: 600;
    color: $text-color;
  }

  .formSection {
    background-color: $secondary-bg-color;
    border-radius: 16px;
    padding: 12px;
    margin-bottom: 24px;

    h2 {
      font-size: 18px;
      font-weight: 600;
      color: $text-color;
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  .formRow {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .formColumn {
    flex: 1;
  }

  .fullWidth {
    width: 100%;
  }

  .switchContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .switchLabel {
    font-size: 12px;
    color: $hint-color;
    margin: 0px 0 0px 8px;
  }

  .permissionRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    .permissionLabel {
      flex: 1;
      font-size: 16px;
      color: $text-color;
      margin: 0;
    }

    .switchWrapper {
      width: 400px;
      font-size: 15px !important;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .textarea {
    width: 100%;
    min-height: 80px;
    padding: 12px;
    box-sizing: border-box;
    border: none;
    border-radius: 12px;
    background-color: $bg-color;
    color: $text-color;
    font-family: inherit;
    font-size: 16px;
    resize: vertical;

    &::placeholder {
      color: $hint-color;
    }

    &:focus {
      outline: none;
      border: 1px solid $button-color;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .buttonContainer {
    margin-top: 32px;
    margin-bottom: 40px;
  }
}

.successContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  padding: 20px;

  .successMessage {
    background-color: $secondary-bg-color;
    border-radius: 16px;
    padding: 32px;
    text-align: center;
    max-width: 400px;

    h2 {
      font-size: 24px;
      color: $button-color;
      margin-top: 0;
      margin-bottom: 16px;
    }

    p {
      font-size: 16px;
      color: $text-color;
      margin-bottom: 24px;
    }
  }
}