@import '../../variables.scss';

.container {
  background-color: $bg-color;
}

.booking-requests-screen {
    width: 100%;
    max-width: 800px;
    padding: 16px;
    margin: 0 auto;
    margin-bottom: 40px;

    h3 {
      font-size: 14px;
      color: $hint-color;
      margin: 0 0 8px 0;
    }
  
    h2 {
      color: $text-color;
      font-size: 18px;
      margin: 0 0 8px 0;
    }

    .booking-actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;

      .action-buttons {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  
    .booking-card {
      background: $secondary-bg-color;
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      transition: all 0.3s ease;
      border: 2px solid transparent;

      &[data-type="automatic"] {
        border: 1px dashed $accent-text-color;
      }
      
      &.deleting {
        border-color: #ef5350;
        opacity: 0.7;
        transform: scale(0.98);
      }

      .delete-button {
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
  
      .booking-info {
        flex: 1;
        width: 100%;
  
        .user-info {
          margin-bottom: 12px;
  
          h3 {
            margin: 0;
            font-size: 18px;
            color: $text-color;
          }
  
          .phone {
            color: $accent-text-color;
            font-size: 15px;
            text-decoration: none; /* убрать подчёркивание */

            &:hover {
              text-decoration: underline; /* подчёркивание при наведении */
            }
          }
        }
  
        .booking-details {
          display: flex;
          flex-direction: column;
          gap: 4px;
          font-size: 14px;
          color: $text-color;
  
          .date {
            font-weight: 500;
            font-size: 12px;
          }

          .time {
            color: $hint-color;
          }
  
          .price {
            color: #98CB4C;
            font-weight: 500;
          }

          .event-type {
            color: #907641;
          }
          
          .room {
            color: $text-color;
            font-weight: 600;
          }

          .type {
            color: $hint-color;
          }
        }
      }
  
      .booking-actions {
        display: flex;
        align-items: center;
        gap: 8px;
  
        .price-input-group {
          position: relative;
          color: $text-color;
  
          input {
            width: 88px;
            height: 44px;
            padding: 8px;
            box-sizing: border-box;
            background-color: $bg-color;
            border: none;
            border-radius: 8px;
            font-size: 14px;
            color: $text-color;
  
            &.error {
              border-color: #ff4444;
            }
  
            &:focus {
              outline: none;
              border-color: #98CB4C;
            }
          }
  
          .error-message {
            position: absolute;
            bottom: -20px;
            left: 0;
            color: #ff4444;
            font-size: 12px;
            white-space: nowrap;
          }
        }
  
        button {
          background: none;
          border: none;
          padding: 8px;
          cursor: pointer;
          transition: opacity 0.2s;
  
          &:hover {
            opacity: 0.7;
          }
  
          &:disabled {
            opacity: 0.3;
            cursor: not-allowed;
          }
  
          img {
            width: 20px;
            height: 20px;
          }
        }
  
        .confirm-button {
          width: 40px;
          height: 40px;
          border-radius: 12px;
          background-color: $button-text-color-opacity;

          img {
            margin-top: 2px;
          }
        }
  
        .delete-button {
          width: 40px;
          height: 40px;
          border-radius: 12px;
          background: rgba(208, 44, 44, 0.15);
        }
      }
    }
  
    .pending-bookings {
      margin-bottom: 40px;
    }
  
    .confirmed-bookings {
      .booking-card {
        background: $secondary-bg-color;
      }
    }
  }
  
  @media (max-width: 600px) {
    .booking-requests-screen {
  
      .booking-card {
        flex-direction: row;
  
        .booking-actions {
          justify-content: flex-end;
        }
      }
    }
  }

  .loading {
    text-align: center;
    padding: 20px;
    color: $hint-color;
    font-size: 16px;
  }
  
  .no-bookings {
    text-align: center;
    padding: 20px;
    color: $hint-color;
    font-size: 14px;
  }
  
  .status {
    font-weight: 500;
    font-size: 14px;
    
    &.status-confirmed {
      color: #98CB4C;
    }
    
    &.status-pending {
      color: #FFA726;
    }
    
    &.status-cancelled {
      color: #EF5350;
    }
  }
  .search-container {
    margin-bottom: 24px;
    display: flex;
    
    .search-input {
      width: 100%;
      height: 48px;
      padding: 0 16px;
      box-sizing: border-box;
      background-color: $secondary-bg-color;
      border: none;
      border-radius: 8px;
      font-size: 16px;
      color: $text-color;
      transition: all 0.3s ease;
      
      &::placeholder {
        color: $hint-color;
      }
      
      &:focus {
        outline: none;
        background-color: $secondary-bg-color;
      }
    }

    .search-stats {
      margin-top: 8px;
      font-size: 14px;
      color: $hint-color;
    }
  }

  .auto-bookings-toggle {
    display: flex;
    align-items: left;
    gap: 2px;
    margin-left: 8px;
    cursor: pointer;
    user-select: none;
    flex-direction: column;
    
    .toggle-label {
      font-size: 12px;
      color: $hint-color;
    }
  
    .toggle-switch {
      width: 48px;
      height: 24px;
      background-color: $secondary-bg-color;
      border-radius: 12px;
      position: relative;
      transition: background-color 0.3s ease;
      
      &.active {
        background-color: $button-color;
      }
      
      .toggle-handle {
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 2px;
        transition: transform 0.3s ease;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      }
      
      &.active .toggle-handle {
        transform: translateX(24px);
      }
    }
  
    &:hover .toggle-switch {
      &:not(.active) {
        opacity: 0.95;
      }
      &.active {
        opacity: 0.95;
      }
    }
  }

  .booking-card {
    background-color: $bg-color;
  }