@import '../../../../variables.scss';

.groupHeader {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 16px;
  position: relative;
  
  .imageContainer {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 16px;
    border-radius: 8px;
    overflow: hidden;

    .groupImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .imagePlaceholder {
      width: 100%;
      height: 100%;
      background-color: #a5a5a5;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 14px;
    }
  }

  .groupInfo {
    flex: 1;
    min-width: 0; // For proper text overflow handling
    
    .groupName {
      font-size: 17px;
      font-weight: 600;
      color: $text-color;
      margin: 0 0 4px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    .ageRange {
      font-size: 13px;
      color: $hint-color;
      margin: 0 0 4px 0;
    }
    
    .studentsCount {
      font-size: 13px;
      color: $text-color;
      margin: -2px 0 4px 0;
    }
    
    .detailsButton {
      background: none;
      border: none;
      padding: 0;
      font-weight: 600;
      color: $accent-text-color;
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  .editButton {
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    color: $accent-text-color;
    font-size: 14px;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
    
    &:hover {
      background-color: rgba($button-color, 0.1);
    }
  }
  
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    
    .imageContainer {
      margin-right: 0;
      margin-bottom: 12px;
    }
    
    .editButton {
      position: relative;
      top: auto;
      right: auto;
      margin-top: 12px;
      align-self: flex-end;
    }
  }
}