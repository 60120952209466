@import '../../variables.scss';

.auth-card {
  padding: 16px;
  max-width: 600px;
  margin: 0 auto;
  background-color: $bg-color;
  border-radius: 12px;
  transition: all 0.3s ease;
  
  &.expanded {
    min-height: 400px;
  }
}

.admin-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.admin-header {
  margin-bottom: 4px;
}

.admin-button2 {
    display: inline-flex;
  position: relative;
  width: 100%;
  margin: 0 !important;
  border: none;
  border-radius: 12px !important;
  color: $text-color;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  &:hover {
    opacity: 0.96;
  }

  .preview-image {
    width: 132px;
    height: 132px;
    object-fit: cover;
    margin-bottom: -20px;
  }

  &.back {
    background-color: transparent;
    color: $accent-text-color;
    padding: 8px 0;
    width: auto;
  }
}

.download-button {
    background-color: rgb(from var(--tg-theme-button-color) r g b / 0.2) !important;
  flex-direction: row;
  align-items: center !important;
  justify-content: center !important;
  height: 48px !important;
  color: $button-color !important;
  
  .excel-icon {
    width: 40px;
    height: 40px;
  }
}

$button-color-opacity: rgba($button-color, 0.18);

@function get-rgb-components($color) {
    @return red($color), green($color), blue($color);
  }

.cards-button, .auto-button, .all-bookings-button {
    background-color: rgb(from var(--tg-theme-button-color) r g b / 0.2) !important;
    padding: 4px 12px 16px 12px !important;
    color: $button-color !important;
}

.control-button {
    background-color: $button-color !important;
    padding: 4px 12px 16px 12px !important;
    color: $button-text-color;
}

.logout-button {
  background-color: rgb(from var(--tg-theme-button-color) r g b / 0.2) !important;
  padding: 12px !important;
  color: $button-color !important;

  .logouttext {
    margin: auto;
  }
}

.earnings-block {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  .mini-module {
    background-color: $secondary-bg-color;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 12px;

    .earn-date {
      color: $hint-color;
    }

    .earn-sum {
      color: $text-color;
    }
  }
}

.booking-card {
  background-color: $bg-color;
}