@import '../../variables.scss';

.auth-card {
    margin: auto;
}

.phone-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h1 {
    margin: 0 0 1.5rem;
    font-size: 20px;
    color: $text-color;
    font-weight: 600;
    text-align: center;
  }

  .digits-container {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 24px;
    width: 100%;
    max-width: 400px;
    padding: 0 8px;
    box-sizing: border-box;

    .prefix {
      font-size: 16px;
      color: $text-color;
      margin-right: 4px;
      flex-shrink: 0;
    }

    .digit-input {
      flex: 1;
      min-width: 0; // Важно для корректной работы flex
      height: 44px;
      border: 2px solid $secondary-bg-color;
      background-color: $secondary-bg-color;
      border-radius: 12px;
      text-align: center;
      font-size: 20px;
      color: $text-color;
      transition: all 0.2s ease;
      padding: 0;

      &:focus {
        outline: none;
        border: 2px solid $button-color;
      }

      &.current {
        border-color: $button-color;
      }
    }
  }

  .error-container {
    width: 100%;
    max-width: 400px;
    padding: 0 16px;
    margin-top: 16px;
    box-sizing: border-box;

    .error-message {
      padding: 12px;
      color: #FF4444;
      text-align: center;
      margin-bottom: 16px;
    }

    .register-button {
      width: 100%;
      padding: 0.75rem;
      background-color: $button-color;
      color: $button-text-color;
      border: none;
      border-radius: 6px;
      font-size: 1rem;
      cursor: pointer;
      transition: opacity 0.1s ease-in-out, scale 0.1s ease-in-out;

      &:hover {
        opacity: 0.85;
        scale: 0.99;
      }
    }
  }
}

// Медиа-запрос для очень маленьких экранов
@media screen and (max-width: 360px) {
  .phone-input-container {
    .digits-container {
      gap: 2px;
      padding: 0 8px;

      .digit-input {
        font-size: 18px;
      }

      .prefix {
        font-size: 18px;
      }
    }
  }
}