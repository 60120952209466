// src/bookings/components/molecules/BookingCard.scss
@import '../../../variables.scss';

.booking-card {
  background: $secondary-bg-color;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 0.2s ease;
  border: 2px solid transparent;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &[data-type="automatic"] {
    border: 1px dashed $accent-text-color;
  }

  &.expanded {
    opacity: 0.8;
  }

  .booking-info {
    flex: 1;
    width: 100%;

    .user-info {
      margin-bottom: 12px;

      h3 {
        margin: 0;
        font-size: 18px;
        color: $text-color;
      }

      .phone {
        display: block;
        color: $accent-text-color;
        font-size: 15px;
        text-decoration: none;
        margin-top: 4px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .booking-details {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 14px;
      color: $text-color;

      .date {
        font-weight: 500;
        font-size: 12px;
      }

      .time {
        color: $hint-color;
      }

      .room {
        color: $text-color;
        font-weight: 600;
      }

      .type {
        color: $hint-color;
      }

      .people {
        color: $hint-color;
      }

      .event-type {
        color: #907641;
      }

      .price-section {
        margin: 0px 0;

        .price-display {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 8px;

          .price {
            color: #98CB4C;
            font-weight: 500;
          }

          .edit-hint {
            font-size: 12px;
            color: $hint-color;
            opacity: 0;
            transition: opacity 0.2s;
          }

          &:hover .edit-hint {
            opacity: 1;
          }
        }

        .price-edit {
          display: flex;
          gap: 8px;
          align-items: center;

          .price-input {
            width: 88px;
            height: 32px;
            padding: 4px 8px;
            border: none;
            border-radius: 4px;
            background: $bg-color;
            color: $text-color;

            &:focus {
              outline: none;
              border: 1px solid $accent-text-color;
            }
          }

          button {
            padding: 4px 8px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 14px;

            &.save-price {
              background: $button-color;
              color: $button-text-color;
            }

            &.cancel-edit {
              background: rgba($hint-color, 0.1);
              color: $hint-color;
            }
          }
        }
      }

      .status {
        font-weight: 500;
        font-size: 14px;
        display: inline-block;
        padding: 4px 8px;
        border-radius: 4px;
        margin-top: 4px;
        
        &.status-confirmed {
          color: #98CB4C;
          background: rgba(152, 203, 76, 0.1);
        }
        
        &.status-pending {
          color: #FFA726;
          background: rgba(255, 167, 38, 0.1);
        }
        
        &.status-cancelled {
          color: #EF5350;
          background: rgba(239, 83, 80, 0.1);
        }
      }
    }
  }

  .booking-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    .action-buttons {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .price-input-group {
      position: relative;

      input {
        width: 88px;
        height: 44px;
        padding: 8px;
        box-sizing: border-box;
        background-color: $bg-color;
        border: none;
        border-radius: 8px;
        font-size: 14px;
        color: $text-color;

        &.error {
          border: 1px solid #ff4444;
        }

        &:focus {
          outline: none;
          border-color: #98CB4C;
        }
      }

      .error-message {
        position: absolute;
        bottom: -20px;
        left: 0;
        color: #ff4444;
        font-size: 12px;
        white-space: nowrap;
      }
    }

    button {
      width: 40px;
      height: 40px;
      border: none;
      border-radius: 12px;
      cursor: pointer;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      img {
        width: 20px;
        height: 20px;
      }

      &.confirm-button {
        background-color: $button-text-color-opacity;

        img {
          margin-top: 2px;
        }
      }

      &.delete-button {
        background: rgba(208, 44, 44, 0.15);
      }
    }
  }
}

@media (max-width: 600px) {
  .booking-card {
    flex-direction: row;

    .booking-actions {
      margin-top: 0;
      justify-content: flex-end;
    }
  }
}