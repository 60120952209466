@import '../../../../variables.scss';

.container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 14px 16px;
    box-sizing: border-box;
    border: 1px solid;
    border-radius: 12px;
    gap: 8px;
    background-color: $bg-color;
    transition: all 0.2s ease;
  
    &.inactive {
      border-color: $section-separator-color;
    }
  
    &.active {
      border-color: $button-color;
    }
  
    &.filled {
      border-color: $button-color;
    }
  
    &.type-text {
      .input {
        padding: 0;
      }
    }

    &.readOnly {
      background-color: var(--tg-theme-section-separator-color);
      pointer-events: none;
  
      .input {
        cursor: default;
        pointer-events: none;
        opacity: 0.8;
      }
    }

    .togglePassword {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      width: 40px;
      height: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.6;
      transition: opacity 0.2s ease;
  
      &:hover {
        opacity: 1;
      }
  
      svg {
        width: 30px;
        height: 20px;
        
        path {
          fill: var(--tg-theme-hint-color);
        }
      }
    }
  }
  
  .prefix {
    color: var(--tg-theme-hint-color);
    font-family: "SF Pro-Regular", Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  
  .input {
    flex: 1;
    border: none;
    outline: none;
    background: none;
    font-family: "SF Pro-Regular", Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: var(--tg-theme-text-color);
    width: 100%;
  
    &::placeholder {
      color: var(--tg-theme-hint-color);
    }
  }

  .upperLabel {
    display: block;
    color: $hint-color;
    font-size: 12px;
    margin-bottom: 4px;
    margin-left: 8px;
  }

  .errorMessage {
    padding-top: 4px;
    padding-left: 8px;
    font-size: 13px;
    color: #f44336;
  }