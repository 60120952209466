@import '../../variables.scss';

.management-container {
    width: 100%;
    max-width: 800px;
    padding: 16px;
    margin-bottom: 40px;
  }
  
  .management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  
    h2 {
      font-size: 24px;
      font-weight: 600;
      color: $text-color;
    }
  }
  
  .add-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #4CAF50;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #45a049;
    }
  
    .icon {
      width: 16px;
      height: 16px;
    }
  }
  
  .edit-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    background-color: $secondary-bg-color;
    color: $text-color;
  
    &:focus {
      outline: none;
      border-color: #4CAF50;
    }
  }
  
  .price-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  
  .photo-inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .photo-input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .photo-preview {
    width: 200px;
    height: 150px;
    border: none;
    border-radius: 6px;
    overflow: hidden;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .textarea {
    width: 100%;
    min-height: 100px;
    box-sizing: border-box;
    padding: 10px;
    border: none;
    border-radius: 6px;
    background-color: $secondary-bg-color;
    color: $text-color;
    font-size: 14px;
    resize: vertical;
  
    &:focus {
      outline: none;
      border-color: #4CAF50;
    }
  }
  
  .button-group {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 16px;
  }
  
  .rooms-list {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
    gap: 4px;
    align-self: stretch;
    flex-wrap: wrap;
  }
  
  .room-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #f8f8f8;
    border-radius: 8px;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #f0f0f0;
    }
  }
  
  .room-info {
    h3 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 4px;
    }
  
    p {
      font-size: 14px;
      color: $text-color;
    }
  }
  
  .room-actions {
    display: flex;
    gap: 8px;
  }
  
  .edit-button,
  .delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 40px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  
    .icon {
      width: 16px;
      height: 16px;
    }
  }
  
  .edit-button {
    background-color: #2196F3;
    color: white;
  
    &:hover {
      background-color: #1976D2;
    }
  }
  
  .delete-button {
    background-color: #F44336;
    color: white;
  
    &:hover {
      background-color: #D32F2F;
    }
  }
  
  .save-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $button-color;
    color: $button-text-color;
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #45a049;
    }
  }
  
  .cancel-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #9e9e9e;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #757575;
    }
  }

  .room-card {
    background: $secondary-bg-color;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 20px;
    width: 100%;
    min-width: 200px;
    max-width: 400px;
    
    .room-photos {
      display: flex;
      height: 200px;
      
      .photo-container {
        flex: 1;
        overflow: hidden;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        
        &:first-child {
          border-right: 2px solid white;
        }
      }
    }
    
    .room-content {
        display: flex;
        padding: 8px 8px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
      
      h3 {
        font-size: 20px;
        color: $text-color;
        font-weight: 800;
        margin-bottom: 10px;
        margin: 4px 0 0 0;
      }
      
      .price-info {
        color: $hint-color;
        line-height: 1.5;
        margin-bottom: 15px;
        margin: 0;
      }
      
      h4 {
        font-size: 16px;
        font-weight: 600;
        color: $text-color;
        margin-bottom: 5px;
        margin: 8px 0 0 0;
      }
      
      .description {
        color: gray;
        line-height: 1.4;
        margin: 0 0 8px 0;
      }
    }
    
    .room-actions {
      padding: 0 8px 8px;
      display: flex;
      gap: 4px;
      
      button {
        flex: 1;
        padding: 8px;
        width: 100%;
        border: none;
        border-radius: 8px;
        cursor: pointer;
      }
      
      .edit-button {
        background-color: #2196F3;
        color: white;
        
        &:hover {
          background-color: #1976D2;
        }
      }
      
      .delete-button {
        background-color: #F44336;
        color: white;
        
        &:hover {
          background-color: #D32F2F;
        }
      }
    }
  }

  .booking-card {
    background-color: $bg-color;
  }