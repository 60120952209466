@import '../../variables.scss';

.automatic-booking-container {
    width: 100%;
    padding: 16px;
    margin-bottom: 80px;
    max-width: 600px;
    
    .booking-form {
      h2 {
        color: $text-color;
        font-size: 24px;
        margin: none;
        font-weight: 500;
      }

      .room-select {
        width: 100%;
        padding: 12px;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        outline: none;
        background-color: $secondary-bg-color;
        cursor: pointer;
        color: $text-color;
      
        &:focus {
            border: 1px solid #80BF33;
        }
      
        option {
          padding: 8px;
        }
      }
  
      .input-group {
        margin-bottom: 24px;

        input.error {
            border-color: #ff4444;
          }
        
          .error-message {
            color: #ff4444;
            font-size: 12px;
            margin-top: 4px;
          }
        
          .checking-message {
            color: #666;
            font-size: 12px;
            margin-top: 4px;
          }
  
        label {
          display: block;
          color: #666;
          font-size: 14px;
          margin-bottom: 8px;
          font-weight: 500;
        }
  
        input {
          width: 100%;
          padding: 12px;
          border: 1px solid #ddd;
          border-radius: 8px;
          font-size: 16px;
          outline: none;
  
          &:focus {
            border-color: #98CB4C;
          }
        }
      }
  
      .day-selector {
        margin-bottom: 24px;
  
        label {
          display: block;
          color: #666;
          font-size: 14px;
          margin-bottom: 12px;
          font-weight: 500;
        }
  
        .days {
            display: flex;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;
  
          .day-button {
            padding: 8px 16px;
            border: none;
            background-color: $button-text-color-opacity;
            border-radius: 8px;
            font-size: 14px;
            cursor: pointer;
            transition: all 0.05s ease;
            color: $accent-text-color;
  
            &:hover {
                background-color: $button-color;
                color: $button-text-color;
                opacity: 0.5;
            }
  
            &.active {
                background-color: $button-color;
                color: $button-text-color;
            }
          }
        }
      }
  
      .time-slots {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        align-content: flex-end;
        gap: 6px;
        align-self: stretch;
        flex-wrap: wrap;
        margin-bottom: 8px;
  
        .time-slot {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 8px;
          background-color: rgba(101, 101, 101, 0.16);
          border: none;
          border-radius: 8px;
          max-width: 80px;
          color: $text-color;
          cursor: pointer;
          transition: all 0.2s ease;
  
          &:hover {
            background-color: rgba(101, 101, 101, 0.25);
          }
  
          &.active {
            background-color: $button-color;
            color: $button-text-color;
          }
  
          .time-range {
            font-size: 12px;
            color: inherit;
            opacity: 0.8;
            margin-top: 4px;
          }
        }
      }
  
      .create-button {
        width: 100%;
        padding: 16px;
        background-color: $button-color;
        color: $button-text-color;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.2s ease;
  
        &:hover {
          opacity: 0.8;
          scale: 0.997;
        }
  
        &:disabled {
          background-color: $button-text-color-opacity;
          color: $accent-text-color;
          cursor: not-allowed;
        }
      }
    }
  
    .bookings-list {
      margin-top: 32px;
  
      .booking-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        background-color: $secondary-bg-color;
        border-radius: 8px;
        margin-bottom: 12px;
  
        .booking-info {
            .room-info {
              font-size: 14px;
              color: $hint-color;
              margin: 4px 0;
            }
          .user-info {
            margin-bottom: 4px;
  
            .name {
              font-weight: 500;
              margin-right: 8px;
              color: $text-color;
            }
  
            .phone {
              color: #666;
            }
          }
  
          .time-info {
            font-size: 14px;
            color: #444;
          }
        }
  
        .delete-button {
          background: rgba(208, 44, 44, 0.15);
          width: 40px;
          border: none;
          padding: 8px;
          cursor: pointer;
          opacity: 0.6;
          transition: opacity 0.2s ease;
  
          &:hover {
            opacity: 1;
          }
  
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  
  // Добавим медиа-запрос для адаптивности на мобильных устройствах
  @media (max-width: 768px) {
    .automatic-booking-container {
      .booking-form {
        .time-slots {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  
  // Добавим медиа-запрос для очень маленьких экранов
  @media (max-width: 480px) {
    .automatic-booking-container {
      .booking-form {
        .time-slots {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }

  .booking-card {
    background-color: $bg-color;
  }