@import '../../variables.scss';

.booking-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 80px; // Отступ для фиксированной кнопки

  // Navigation tabs
  .navigation-tabs {
    display: flex;
    padding-top: 8px;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-bottom: 12px;

    .tab-button {
      padding: 12px 24px;
      border: none;
      border-radius: 12px;
      font-size: 16px;
      cursor: pointer;
      transition: all 0.2s;
      flex: 1 1;

      &.active {
        background-color: $button-color;
        color: $button-text-color;
      }

      &.inactive {
        background-color: $button-text-color-opacity;
        color: $accent-text-color;
      }

      &:not(.active) {
        background-color: $button-text-color-opacity;
        color: $accent-text-color;
      }

      &:hover:not(.active) {
        background-color: $button-text-color-opacity;
      }
    }
  }

  // Room photos
  .room-photos {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    overflow: hidden;

    .photo-container {
      width: 100%;
      height: 117px;
      object-fit: cover;
      border-radius: 8px;

      img {
        width: 100%;
        height: 117px;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  // Booking steps
  .booking-steps {
    margin-bottom: 128px;
    .booking-step {
      margin-bottom: 16px;
      opacity: 0.5;
      transition: opacity 0.1s;

      &.active {
        opacity: 1;

        h2 {
          color: $text-color;
          opacity: 1;
        }
      }

      h2 {
        color: $accent-text-color;
        opacity: 0.75;
        font-size: 18px;
        margin-bottom: 0 0 8px 0;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          opacity: 1;
        }
      }

      &:hover {
        opacity: 1;
      }

      // Calendar
      .calendar {
        .weekdays {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;

          .weekday {
            flex: 1;
            text-align: center;
            color: $hint-color;
            font-size: 14px;
          }
        }

        .dates {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          gap: 4px;

          .date-button {
            aspect-ratio: 1;
            border: none;
            border-radius: 8px;
            background-color: $secondary-bg-color;
            color: $text-color;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.2s;

            &:hover:not(:disabled) {
              background-color: $button-text-color-opacity;
            }

            &.active {
              background-color: $button-color;
              color: $button-text-color;
            }

            &:disabled {
              cursor: not-allowed;

              &:hover {
                background-color: $secondary-bg-color;
              }
            }
          }
        }
      }

      // Time slots
      .time-slots {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px;
        margin-bottom: 16px;

        // Выбранная ячейка
        .selected {
          display: flex;
          max-width: 100px;
          flex-direction: column;
          align-items: center;
          padding: 8px;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.2s;
          font-size: 14px;
          background-color: $button-color;
          color: $button-text-color;

          .time-range {
            font-size: 12px !important;
            color: $button-text-color;
          }
        }

        .time-slot {
          display: flex;
          max-width: 100px;
          flex-direction: column;
          align-items: center;
          padding: 8px;
          border: none;
          border-radius: 8px;
          background-color: $button-text-color-opacity;
          color: $text-color;
          cursor: pointer;
          transition: all 0.2s;
          touch-action: manipulation;
          -webkit-tap-highlight-color: transparent;

          .time-range {
            font-size: 12px;
            color: $hint-color;
          }

          &:hover:not(:disabled) {
            background-color: $button-text-color-opacity;
          }

          &:active {
            transition: none;
          }

          &.booked {
            background-color: rgba(255, 59, 48, 0.15);
            color: #FF3B30;
            cursor: not-allowed;
      
            .time-range {
              color: #FF3B30;
            }
      
            &:hover {
              background-color: rgba(255, 59, 48, 0.2);
            }
          }
      
          &.my-booking {
            background-color: $button-text-color-opacity;
            color: $accent-text-color;
            border: 1px solid $accent-text-color;
      
            .time-range {
              color: $accent-text-color;
            }
      
            &:hover {
              background-color: $button-text-color-opacity;
            }
          }
        }
      }

      // Input fields
      .people-input,
      .event-input {
        width: 100%;
        padding: 12px;
        margin-bottom: 8px;
        border: none;
        border-radius: 12px;
        background-color: $secondary-bg-color;
        box-sizing: border-box;
        color: $text-color;
        font-size: 16px;

        &::placeholder {
          color: $hint-color;
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px $button-text-color-opacity;
        }
      }
    }
  }

  // Action buttons
  .confirm-button,
  .submit-button {
    width: 100%;
    padding: 16px;
    margin-bottom: 400px;
    border: none;
    border-radius: 12px;
    background-color: $button-color;
    color: $button-text-color;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover:not(:disabled) {
      opacity: 0.9;
    }

    &:disabled {
      background-color: $secondary-bg-color;
      color: $hint-color;
      cursor: not-allowed;
    }
  }

  // Fixed Action Button Container
  .fixed-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    background: $bg-color;
    padding: 8px 8px 24px 8px;
    border-top: 1px solid $secondary-bg-color;
    box-sizing: border-box;
    transform: translateY(100%);
    animation: slideUp 0.3s forwards;

    .fixed-action-button {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      display: block;
      padding: 16px;
      background-color: $button-color;
      color: $button-text-color;
      border: none;
      border-radius: 12px;
      font-size: 16px;
      cursor: pointer;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.8;
      }
    }
  }

  // Анимация появления кнопки
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
}

// Media queries
@media (max-width: 768px) {
  .booking-container {
    padding: 16px;

    .time-slots {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }
}

@media (max-width: 480px) {
  .booking-container {
    padding: 12px;

    .navigation-tabs {
      display: flex;
      padding-top: 8px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .tab-button {
        padding: 8px 16px;
        font-size: 14px;
      }
    }

    .time-slots {
      grid-template-columns: repeat(4, 1fr) !important;
    }

    h2 {
      font-size: 18px !important;
    }

    .time-slot {
      font-size: 14px;
      max-width: 100px;

      .time-range {
        font-size: 11px !important;
      }
    }
  }
}

// Media queries
@media (max-width: 768px) {
  .booking-container {
    padding: 16px;

    .time-slots {
      grid-template-columns: repeat(3, 1fr) !important;
    }

    .booking-type {
      flex-direction: row;

      .type-button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .booking-container {
    padding: 12px;

    .navigation-tabs {
        display: flex;
        padding-top: 8px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

      .tab-button {
        padding: 8px 16px;
        font-size: 14px;
      }
    }

    .time-slots {
      grid-template-columns: repeat(4, 1fr) !important;
    }

    h2 {
      font-size: 18px !important;
    }

    .time-slot {
      font-size: 14px;
      max-width: 100px;

      .time-range {
        font-size: 11px !important;
      }
    }

    .confirm-button,
    .submit-button {
      padding: 14px;
      font-size: 14px;
      margin-bottom: 400px;
    }
  }
}

.confirmation-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  text-align: center;
  color: $text-color;

  .confirmation-actions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .upload-button {
    width: 100%;
    padding: 16px;
    border: none;
    border-radius: 12px;
    background-color: $button-text-color-opacity;
    color: $accent-text-color;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover:not(:disabled) {
      opacity: 0.9;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .loader-svg {
    width: 128px !important;
    height: 128px !important;
    margin-bottom: 24px;

    // Заменяем #777777 на $text-color
    [fill="#777777"] {
      fill: $text-color;
    }
    [stroke="#777777"] {
      stroke: $text-color;
    }

    // Заменяем #f57800 на $button-color
    [fill="#f57800"], [stroke="#f57800"] {
      fill: $button-color;
      stroke: $button-color;
    }
  }

  .confirmation-text {
    margin-bottom: 8px;

    p {
      margin: 4px 0;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .confirmation-rules {
    margin-bottom: 16px;

    p {
      margin: 12px 0;
      font-size: 16px;
      opacity: 0.6;
    }

    .important {
      color: $accent-text-color;
      text-decoration: underline !important;
    }
  }

  .submit-button {
    width: 100%;
    margin-bottom: 0;
  }
}

.booking-card {
  background-color: $bg-color;
}