@import '../../../../../variables.scss';

.groupDetailContainer {
  padding: 20px;
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .groupHero {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 32px;
    background-color: $secondary-bg-color;
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    
    @media (max-width: 768px) {
      flex-direction: column;
    }
    
    .imageContainer {
      width: 100%;
      max-width: 300px;
      height: 300px;
      overflow: hidden;
      border-radius: 8px;
      flex-shrink: 0;
      
      @media (max-width: 768px) {
        max-width: 100%;
        height: 200px;
      }
      
      .groupImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
      .imagePlaceholder {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #a5a5a5;
        color: white;
        font-size: 18px;
      }
    }
    
    .groupInfo {
      flex: 1;
      
      .groupName {
        font-size: 28px;
        font-weight: 600;
        color: $text-color;
        margin: 0 0 16px 0;
      }
      
      .infoRows {
        display: flex;
        flex-direction: column;
        gap: 12px;
        
        .infoRow {
          display: flex;
          
          .infoLabel {
            color: $hint-color;
            width: 200px;
          }
          
          .infoValue {
            color: $text-color;
            font-weight: 500;
            
            &.statusValue {
              padding: 4px 10px;
              border-radius: 20px;
              font-size: 14px;
              display: inline-block;
              
              &.activeStatus {
                background-color: rgba(76, 175, 80, 0.1);
                color: #4CAF50;
              }
              
              &.inactiveStatus {
                background-color: rgba(244, 67, 54, 0.1);
                color: #f44336;
              }
            }
          }
        }
      }
    }
  }
  
  .groupDescription {
    background-color: $secondary-bg-color;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 32px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    
    .sectionTitle {
      font-size: 20px;
      font-weight: 600;
      color: $text-color;
      margin: 0 0 16px 0;
    }
    
    .descriptionText {
      color: $text-color;
      line-height: 1.5;
      white-space: pre-line;
    }
  }
  
  .tabSection {
    .tabs {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;
      
      .tabButton {
        background: none;
        border: none;
        padding: 8px 16px;
        border-radius: 24px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;
        color: $hint-color;
        background-color: $secondary-bg-color;
        
        &:hover {
          opacity: 0.9;
        }
        
        &.activeTab {
          color: $button-text-color;
          background-color: $button-color;
        }
      }
    }
    
    .tabContent {
      background-color: $secondary-bg-color;
      border-radius: 16px;
      padding: 24px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      
      .tabHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        
        .contentTitle {
          font-size: 20px;
          font-weight: 600;
          color: $text-color;
          margin: 0;
        }
      }
      
      .lessonsGrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 20px;
        
        .lessonCardWrapper {
          width: 100%;
        }
      }
      
      .studentsTable {
        width: 100%;
        overflow-x: auto;
        
        table {
          width: 100%;
          border-collapse: collapse;
          
          th, td {
            padding: 12px 16px;
            text-align: left;
            border-bottom: 1px solid $section-separator-color;
          }
          
          th {
            font-weight: 600;
            color: $hint-color;
            font-size: 14px;
          }
          
          td {
            color: $text-color;
          }
          
          tr:last-child td {
            border-bottom: none;
          }
          
          .statusBadge {
            display: inline-block;
            padding: 4px 10px;
            border-radius: 12px;
            font-size: 14px;
            
            &.active {
              background-color: rgba(76, 175, 80, 0.1);
              color: #4CAF50;
            }
            
            &.inactive {
              background-color: rgba(244, 67, 54, 0.1);
              color: #f44336;
            }
            
            &.pending {
              background-color: rgba(255, 167, 38, 0.1);
              color: #FF9800;
            }
          }
        }
      }
      
      .emptyState {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px;
        color: $hint-color;
        font-style: italic;
      }
    }
  }
  
  .loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 100%;
    
    .loadingSpinner {
      border: 4px solid $secondary-bg-color;
      border-top: 4px solid $button-color;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin-bottom: 16px;
    }
    
    p {
      color: $hint-color;
      font-size: 16px;
    }
  }
  
  .errorContainer {
    text-align: center;
    padding: 40px 20px;
    
    .errorMessage {
      color: $hint-color;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}